import { load } from "@cashfreepayments/cashfree-js";
import { toast } from "react-toastify";
export const handlePayment = async (
  comp,
  paymentSessionId,
  collect_request_id
) => {
  const cashfree = await load({
    mode: process.env.REACT_APP_CASHFREE_MODE,
  });
  const qr = cashfree.create("upiQr", {
    values: {
      size: "157px",
    },
  });
  qr.mount(comp);
  qr.on("paymentrequested", function () {
    //paymentBtn.disabled = true
  });
  qr.on("ready", async function () {
    cashfree
      .pay({
        paymentMethod: qr,
        paymentSessionId: paymentSessionId,
        returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
      })
      .then(function (data) {
        // if (data.error) {
        //   toast.error(data.error.message);
        // }
      })
      .catch((error) => {
        //toast.error(error.message);
      });
  });
};

export const initiateUpi = ({ payment_id, upi_id }) => {
  console.log({ payment_id, upi_id });

  try {
    const form = document.createElement("form");
    form.id = "paymentForm";
    form.method = "POST";
    form.action = "https://pay.easebuzz.in/initiate_seamless_payment/";
    form.className = "hide";

    const input1 = document.createElement("input");
    input1.type = "hidden";
    input1.name = "access_key";
    input1.value = payment_id;

    const input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "payment_mode";
    input2.value = "UPI";

    const input3 = document.createElement("input");
    input3.type = "hidden";
    input3.name = "upi_va";
    input3.value = upi_id;

    form.appendChild(input1);
    form.appendChild(input2);
    form.appendChild(input3);
    document.body.appendChild(form);

    form.submit();
  } catch (error) {
    throw new Error("Method not implemented.");
  }
};
