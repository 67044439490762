import { load } from "@cashfreepayments/cashfree-js";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import tick from "../../assets/tick.svg";
import fadedTick from "../../assets/fadedTick.svg";
import Select from "react-select";
import jioPay from "../../assets/jioPay.png";
import airtelPay from "../../assets/airtelPay.svg";
import mobi from "../../assets/mobi.svg";
import OlaMoney from "../../assets/Ola_Money.png";
import FreeCharge from "../../assets/freecharge_logo.svg";
import Amazon from "../../assets/amazon-2-logo-svgrepo-com.svg";
import paytm from "../../assets/paytmSvg.svg";
import PhonePe from "../../assets/PhonePe-Logo.wine.svg";
import Bajaj from "../../assets/BAJA00000NAT9J.png";
const ACTION_LINK = process.env.REACT_APP_EASEBUZZ_ACTION || "";
const Wallet = (props) => {
  const {
    collect_request_id,
    isBlank,
    walletSet,
    isEasebuzzSchool,
    isBuzz,
    easebuzzPaymentId,
  } = props;

  const [phoneNumber, setPhoneNumber] = useState("");
  const isValidPhoneNumber = /^[6-9]\d{9}$/.test(phoneNumber);
  const { walletName, walletImg, walletTxt, walletHeading } = props.wallet;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [services, setServices] = useState("AIRTLM");
  const payRef = props.payRef;

  useEffect(() => {
    props.finalAmountWithMDR("wallet", walletName);
  }, [walletName, services]);

  useEffect(() => {
    payRef.current.disabled = true;
    if (isValidPhoneNumber) {
      payRef.current.disabled = false;
    }
  }, [phoneNumber]);

  const handleSubmit = async (id) => {
    if (!isValidPhoneNumber) {
      toast.error("Please enter valid Phone Number");
      return;
    }
    if (isBuzz === "true") {
      const form = document.createElement("form");
      form.id = "paymentForm";
      form.method = "POST";
      form.action = ACTION_LINK;
      form.className = "hide";

      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "access_key";
      input1.value = easebuzzPaymentId;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "payment_mode";
      input2.value = "MW";

      const input3 = document.createElement("input");
      input3.type = "hidden";
      input3.name = "bank_code";
      input3.value = services;

      form.appendChild(input1);
      form.appendChild(input2);
      form.appendChild(input3);
      document.body.appendChild(form);
      form.submit();
    } else {
      const cashfree = await load({
        mode: process.env.REACT_APP_CASHFREE_MODE,
      });
      let provider = id;

      let component = cashfree.create("wallet", {
        values: {
          provider: provider,
          phone: phoneNumber,
          buttonIcon: false,
        },
        style: {
          base: {
            fontSize: "22px",
            display: "none",
          },
        },
      });
      component.on("loaderror", (data) => {
        console.error("Load error:", data.error, data.value);
        toast.error("Internal Server Error");
      });
      initPay(component, cashfree, id);
      component.mount("#" + provider);
    }
  };
  function initPay(comp, cashfree, id) {
    comp.on("ready", (d) => {
      cashfree
        .pay({
          paymentMethod: comp,
          paymentSessionId: props.paymentId,
          redirectTarget: isBlank === "true" ? "_blank" : "_self",
          returnUrl: process.env.REACT_APP_RETURN_URL + collect_request_id,
        })
        .then(function (data) {
          comp.enable();

          if (data.error) {
            toast.error(data.error.message);
          }
        });
    });
    comp.on("error", (error) => {
      toast.error(error.message);
    });
  }
  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };
  props.childComponentFunctionRef.current = handleSubmit;
  return (
    <div className="h-full">
      <div className={"p-4 pb-0 " + (menuIsOpen ? "h-80" : "h-auto")}>
        <div className="flex flex-col w-full h-full">
          <label
            className="font-semibold text-[16xp] pl-2"
            htmlFor="bankDropdown"
          >
            Choose Wallet:
          </label>
          <Select
            className=" font-normal p-2 z-[99999999] rounded-lg"
            id="bankDropdown"
            onChange={(e) => {
              setServices(e?.value);
              walletSet(e?.img, e?.walletHeading, e?.walletTxt, e?.walletName);
            }}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            options={
              isBuzz === "true"
                ? [
                    // {
                    //   img: PhonePe,
                    //   walletHeading: "PhonePe Wallet",
                    //   walletTxt: "link your PhonePe Wallet",
                    //   walletName: "phonepe",
                    //   value: "PHONEPE",
                    //   label: "Phone Pe",
                    // },
                    // {
                    //   img: Bajaj,
                    //   walletHeading: "Bajaj Wallet",
                    //   walletTxt: "link your Bajaj Wallet",
                    //   walletName: "bajaj",
                    //   value: "BJFVWLT",
                    //   label: "Bajaj Wallet",
                    // },
                    // {
                    //   img: OlaMoney,
                    //   walletHeading: "OLA money Wallet",
                    //   walletTxt: "link your OLA money Wallet",
                    //   walletName: "ola",
                    //   value: "OLAM",
                    //   label: "Ola Money",
                    // },
                    // {
                    //   img: Amazon,
                    //   walletHeading: "AmazonPay Wallet",
                    //   walletTxt: "link your AmazonPay Wallet",
                    //   walletName: "amazon",
                    //   value: "ATM",
                    //   label: "Amazon",
                    // },
                    {
                      img: airtelPay,
                      walletHeading: "Airtel Wallet",
                      walletTxt: "link your Airtel Wallet",
                      walletName: "airtel",
                      value: "AIRTLM",
                      label: "Airtel Pay",
                    },
                    {
                      img: mobi,
                      walletHeading: "Mobi Wallet",
                      walletTxt: "link your Mobi Wallet",
                      walletName: "mobikwik",
                      value: "MOBKWK",
                      label: "Mobikwik",
                    },
                  ]
                : [
                    {
                      img: PhonePe,
                      walletHeading: "PhonePe Wallet",
                      walletTxt: "link your PhonePe Wallet",
                      walletName: "phonepe",
                      value: "Phone Pe",
                      label: "Phone Pe",
                    },
                    // {
                    //   img: paytm,
                    //   walletHeading: "Paytm Wallet",
                    //   walletTxt: "link your Paytm Wallet",
                    //   walletName: "paytm",
                    //   value: "Paytm",
                    //   label: "Paytm",
                    // },
                    // {
                    //   img: OlaMoney,
                    //   walletHeading: "OLA money Wallet",
                    //   walletTxt: "link your OLA money Wallet",
                    //   walletName: "ola",
                    //   value: "Ola Money",
                    //   label: "Ola Money",
                    // },
                    // {
                    //   img: Amazon,
                    //   walletHeading: "AmazonPay Wallet",
                    //   walletTxt: "link your AmazonPay Wallet",
                    //   walletName: "amazon",
                    //   value: "Amazon",
                    //   label: "Amazon",
                    // },
                    {
                      img: FreeCharge,
                      walletHeading: "FreeCharge Wallet",
                      walletTxt: "link your FreeCharge Wallet",
                      walletName: "freecharge",
                      value: "Free Charge",
                      label: "Free Charge",
                    },
                    // {
                    //   img: jioPay,
                    //   walletHeading: "Jio Wallet",
                    //   walletTxt: "link your Jio Wallet",
                    //   walletName: "jio",
                    //   value: "Jio Pay",
                    //   label: "Jio Pay",
                    // },
                    {
                      img: airtelPay,
                      walletHeading: "Airtel Wallet",
                      walletTxt: "link your Airtel Wallet",
                      walletName: "airtel",
                      value: "Airtel Pay",
                      label: "Airtel Pay",
                    },
                    {
                      img: mobi,
                      walletHeading: "Mobi Wallet",
                      walletTxt: "link your Mobi Wallet",
                      walletName: "mobikwik",
                      value: "Mobikwik",
                      label: "Mobikwik",
                    },
                  ]
            }
            isSearchable
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "#E8EBF6",
                border: "none",
              }),

              input: (provided) => ({
                ...provided,
                backgroundColor: "transparent",
                "::placeholder": {
                  backgroundColor: "#YourDesiredColor",
                  opacity: 1,
                },
              }),
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-3">
        <div className="flex items-center bg-white shadow-black-shadow rounded-lg py-3 px-2 px-1 ml-5 mr-5 mt-2 w-auto">
          <div className="text-basic mr-2 ml-1">+91</div>
          <input
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            value={phoneNumber}
            type="text"
            name="phoneNo"
            id="ph"
            className=" outline-none bg-transparent w-full mx-3 border-b-2 border-grey text-basic "
            placeholder="Phone Number"
            maxLength="10"
            inputMode="numeric"
          />
          {isValidPhoneNumber && <img src={tick} className="m-1" alt="tick" />}
          {!isValidPhoneNumber && (
            <img src={fadedTick} className="m-1" alt="faded" />
          )}
        </div>
      </div>
    </div>
  );
};
export default Wallet;
