import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Sdk = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collectId = queryParams.get("collect_id");
  const isBlank = queryParams.get("isBlank");
  const url = `${process.env.REACT_APP_PAYMENT_BACKEND}/edviron-pg/sdk-redirect?collect_id=${collectId}&isBlank=${isBlank}`;
  useEffect(() => {
    if (collectId) {
      window.location.href = url;
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center justify-center w-[95%]">
        <div className="flex space-x-2 justify-center items-center">
          <span className="sr-only">Loading...</span>
          <div className="h-4 w-4 bg-indigo-500 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-4 w-4 bg-indigo-500 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-4 w-4 bg-indigo-500 rounded-full animate-bounce"></div>

        </div>
        <h1 className="text-2xl  mb-3 font-semibold mt-2 text-center">
          Redirecting to Payment Page...
        </h1>
        <p className="text-sm text-gray-600 w-full flex flex-wrap justify-center">
          Please wait while we redirect you to the payment page.
        </p>
      </div>
    </div>
  );

};

export default Sdk;

