
import React from 'react';

const Failure = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center mx-3">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-red-600 mb-4 text-center">Payment Failed</h2>
        <p className="text-gray-700 text-center">Unfortunately, the payment process failed. Please try again later.</p>
        

      </div>
    </div>
  );
};

export default Failure;
