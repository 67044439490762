import React from "react";

function ReturnPage() {
  return (
    <div>
      <p>Your payment has been processed. You may now close this tab.</p>
    </div>
  );
}

export default ReturnPage;
