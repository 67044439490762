import React from 'react'
import { useLocation } from 'react-router-dom';
const OrderFailed = () => {
    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collectId = queryParams.get('collect_id');
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center mx-3">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold text-red-600 mb-4 text-center">Order id: {collectId} <br /> Not Found </h2>
        <p className="text-gray-700 text-center">Unfortunately, the order you are looking for is not Found .</p>
        
      </div>
    </div>
  )
}

export default OrderFailed