import React, { useEffect } from "react";
const ACTION_LINK = process.env.REACT_APP_CCAVENUE_ACTION_LINK || "";
function Ccavenue() {
  let urlParams = new URLSearchParams(window.location.search);
  const encodedRequest: any = urlParams.get("encRequest");
  const access_code: any = urlParams.get("access_code");
  const submitForm = (encodedRequest: string, access_code: string) => {
    try {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = ACTION_LINK;
      const input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "encRequest";
      input1.id = "encRequest";
      input1.value = encodedRequest;

      const input2 = document.createElement("input");
      input2.type = "hidden";
      input2.name = "access_code";
      input2.id = "access_code";
      input2.value = access_code;

      form.appendChild(input1);
      form.appendChild(input2);
      document.body.appendChild(form);

      form.submit();
    } catch (error) {
      throw new Error("method not implemented");
    }
  };
  useEffect(() => {
    if (encodedRequest && access_code) {
      submitForm(encodedRequest, access_code);
    }
    if (!encodedRequest && !access_code) {
      return window.location.replace("/");
    }
  }, []);

  return (
    <div className="h-screen flex justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=" size-20 animate-spin"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
    </div>
  );
}

export default Ccavenue;
