import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie, { useLottie } from "lottie-react";
import checkMarkAnimation from "../../assets/LottieFiles/CheckMarkAnimation.json";
import ConfettiGenerator from "confetti-js";

const Success = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const collectId = queryParams.get("collect_id");
  const amount = queryParams.get("amount");
  const style = {
    height: 120,
  };
  const options = {
    animationData: checkMarkAnimation,
    loop: true,
    autoplay: false,
    initialSegment: [0],
  };
  const { View, play } = useLottie(options, style);
  const [successIconIn, setSuccessIconIn] = useState(false);
  const [otherInfoIn, setOtherInfoIn] = useState(false);

  const successIcon = () => {
    return View;
  };

  useEffect(() => {
    play();
    const timer = setInterval(() => {
      setSuccessIconIn(true);
    }, 1500);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      if (successIconIn === true) {
        setOtherInfoIn(true);
      }
    }, 1500);
    return () => clearInterval(timer);
  }, [successIconIn]);

  useEffect(() => {
    const confettiSettings = {
      target: "my-canvas",
      max: "350",
      size: "1",
      animate: true,
      props: ["circle", "square", "triangle", "line"],
      colors: [
        [165, 104, 246],
        [230, 61, 135],
        [0, 199, 228],
        [253, 214, 126],
      ],
      clock: "60",
      rotate: true,
      width: "420",
      height: "1200",
      start_from_edge: true,
      respawn: false,
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  return (
    <div className=" max-w-[420px] mx-auto  flex items-center justify-center ">
      <canvas id="my-canvas" className="absolute top-[-0.5rem]"></canvas>
      <div className="bg-white min-h-screen h-full p-8 w-full flex flex-col items-center shadow-lg">
        <div
          className={`transform ease-in-out	 transition-transform duration-300 ${
            successIconIn ? " translate-y-12" : "  translate-y-[20rem]"
          }`}
        >
          {successIcon()}
        </div>

        <div
          className={`pt-6 text-center transform ease-in-out transition-all duration-700 ${
            otherInfoIn
              ? " translate-y-12  opacity-100"
              : "  opacity-0 delay-500 translate-y-[20rem]"
          }`}
        >
          <h2 className="text-2xl font-normal text-black mb-4">
            Payment Successful
          </h2>
          <p className=" px-10 text-[#656565]  font-normal text-base ">
            Thank you for patronizing us today. We value you!
          </p>

          <div className="mt-10">
            {amount && (
              <h2 className="text-4xl font-bold text-[#00A91B] mb-4">
                Rs: {amount}
              </h2>
            )}
            <p className=" px-10 text-[#656565]  font-normal text-base ">
              Ref No. : {collectId}
            </p>
          </div>
        </div>
        <div
          className={`pt-6 text-center transform ease-in-out transition-all duration-700 ${
            otherInfoIn
              ? " translate-y-12  opacity-100"
              : "  opacity-0 delay-500 translate-y-[20rem]"
          }`}
        >
          <div className="mt-10">
            <p className=" px-10 text-[#656565]  font-normal text-base ">
              Kindly Close This Tab
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
