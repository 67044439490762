import { toast } from "react-toastify";
const ACTION_LINK = process.env.REACT_APP_EASEBUZZ_ACTION || "";
export const initiateCard = ({
  payment_id,
  card_number,
  card_holder_name,
  card_cvv,
  card_expiry_date,
  type,
}) => {
  try {
    const form = document.createElement("form");
    form.id = "paymentForm";
    form.method = "POST";
    form.action = ACTION_LINK;
    form.className = "hide";

    const input1 = document.createElement("input");
    input1.type = "hidden";
    input1.name = "access_key";
    input1.value = payment_id;

    const input2 = document.createElement("input");
    input2.type = "hidden";
    input2.name = "payment_mode";
    input2.value = type;

    const input3 = document.createElement("input");
    input3.type = "hidden";
    input3.name = "card_number";
    input3.value = card_number;

    const input4 = document.createElement("input");
    input4.type = "hidden";
    input4.name = "card_holder_name";
    input4.value = card_holder_name;

    const input5 = document.createElement("input");
    input5.type = "hidden";
    input5.name = "card_cvv";
    input5.value = card_cvv;

    const input6 = document.createElement("input");
    input6.type = "hidden";
    input6.name = "card_expiry_date";
    input6.value = card_expiry_date;

    form.appendChild(input1);
    form.appendChild(input2);
    form.appendChild(input3);
    form.appendChild(input4);
    form.appendChild(input5);
    form.appendChild(input6);
    document.body.appendChild(form);

    form.submit();
  } catch (error) {
    if (error.message === "EasebuzzCheckout requires 'key' on initialization") {
      toast.error("No key passed");
    } else {
      toast.error(error.message);
    }
    throw new Error("Method not implemented.");
  }
};
