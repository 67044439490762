import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Callback = () => {
  const location = useLocation();
  // const { encrypt, working_key, order_no } = useParams();

  const [status, setStatus] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const encrypt = params.get("encrypt");
        const working_key = params.get("working_key");
        const order_no = params.get("order_no");
        const access_code = params.get("access_code");
        console.log(order_no, encrypt);

        const data = new URLSearchParams({
          enc_request: encrypt,
          access_code: access_code,
          request_type: "JSON",
          command: "orderStatusTracker",
          order_no: order_no,
        });

        const config = {
          method: "post",
          url: "https://api.ccavenue.com/apis/servlet/DoWebTrans",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data.toString(),
        };

        const response = await axios(config);
        const resparams = new URLSearchParams(response.data);
        const paramObject = Object.fromEntries(resparams.entries());
        console.log(paramObject);

        // Update the status state with the response
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
  }, [location.search]);
  return <div>Callback</div>;
};

export default Callback;
