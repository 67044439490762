const Banks = [
  { name: "AU Small Finance Bank", code: 3087, abbreviation: "AUBLR" },

  { name: "Axis Bank", code: 3003, abbreviation: "UTIBR" },

  { name: "Bandhan Bank - Retail Banking", code: 3088, abbreviation: "BDBLR" },

  {
    name: "Bank of Baroda - Retail Banking",
    code: 3005,
    abbreviation: "BARBR",
  },
  { name: "Bank of India", code: 3006, abbreviation: "BKIDR" },

  { name: "Bank of Maharashtra", code: 3007, abbreviation: "MAHBR" },

  { name: "CSB Bank Limited", code: 3010, abbreviation: "CSBKR" },
  { name: "Canara Bank", code: 3009, abbreviation: "CNRBR" },
  { name: "Capital Small Finance Bank", code: 3098, abbreviation: "CLBLR" },
  { name: "Central Bank of India", code: 3011, abbreviation: "CBINR" },

  { name: "DBS Bank Ltd", code: 3017, abbreviation: "DBSSR" },

  { name: "DCB Bank - Personal", code: 3018, abbreviation: "DCBLR" },
  { name: "Deutsche Bank", code: 3016, abbreviation: "DEUTR" },
  { name: "Dhanlakshmi Bank", code: 3019, abbreviation: "DLXBR" },

  { name: "Equitas Small Finance Bank", code: 3076, abbreviation: "ESFBR" },
  { name: "Federal Bank", code: 3020, abbreviation: "FDRLR" },

  { name: "HDFC Bank", code: 3021, abbreviation: "HDFCR" },

  { name: "ICICI Bank", code: 3022, abbreviation: "ICICR" },
  { name: "ICICI Corporate Netbanking", code: 3073, abbreviation: "ICICC" },
  { name: "IDBI Bank", code: 3023, abbreviation: "IBKLR" },
  { name: "IDFC FIRST Bank", code: 3024, abbreviation: "IDFBR" },
  { name: "Indian Bank", code: 3026, abbreviation: "IDIBR" },
  { name: "Indian Overseas Bank", code: 3027, abbreviation: "IOBAR" },

  { name: "IndusInd Bank", code: 3028, abbreviation: "INDBR" },
  { name: "Jammu and Kashmir Bank", code: 3029, abbreviation: "JAKAR" },

  { name: "Janata Sahakari Bank Ltd Pune", code: 3104, abbreviation: "JSBPR" },

  { name: "Karnataka Bank Ltd", code: 3030, abbreviation: "KARBR" },

  { name: "Karur Vysya Bank", code: 3031, abbreviation: "KVBLR" },

  { name: "Punjab & Sind Bank", code: 3037, abbreviation: "PSIBR" },

  {
    name: "Punjab National Bank - Retail Banking",
    code: 3038,
    abbreviation: "PUNBR",
  },
  { name: "RBL Bank", code: 3039, abbreviation: "RATNR" },

  { name: "Saraswat Bank", code: 3040, abbreviation: "SRCBR" },
  { name: "South Indian Bank", code: 3042, abbreviation: "SIBLR" },
  { name: "Standard Chartered Bank", code: 3043, abbreviation: "SCBLR" },
  { name: "State Bank Of India", code: 3044, abbreviation: "SBINR" },

  { name: "Tamilnad Mercantile Bank Ltd", code: 3052, abbreviation: "TMBLR" },

  { name: "UCO Bank", code: 3054, abbreviation: "UCBAR" },

  { name: "Union Bank of India", code: 3055, abbreviation: "UBINR" },

  { name: "Utkarsh Small Finance Bank", code: 3089, abbreviation: "UTKSR" },

  { name: "Yes Bank Ltd", code: 3058, abbreviation: "YESBR" },
];

const EasebuzzBanks = [
  // {
  //   name: "Allahabad Bank",
  //   value: "ALB",
  // },
  {
    name: "AU Small Finance Bank",
    value: "AUSFB",
  },
  // {
  //   name: "Airtel Payments Bank",
  //   value: "AIRTLM",
  // },
  { name: "Axis Bank", code: 3003, abbreviation: "UTIBR", value: "AXB" },

  {
    name: "Axis Corporate Net banking",
    value: "ACNB",
  },
  {
    name: "Bandhan Bank",
    value: "BANB",
  },
  // {
  //   name: "Bandhan Bank Corporate",
  //   value: "BABC",
  // },
  {
    name: "Bank of Baroda - Corporate Banking",
    value: "BOBCB",
  },
  {
    name: "Bank of Baroda - Retail Banking",
    value: "BOBRB",
  },
  {
    name: "Bank of India",
    value: "BOIND",
  },
  {
    name: "Bank of Maharashtra",
    value: "BOMH",
  },
  {
    name: "Canara Bank",
    value: "CANB",
  },
  // {
  //   name: "Canara Bank (Erstwhile Syndicate Bank)",
  //   value: "SYNB",
  // },
  // {
  //   name: "Capital Small Finance Bank",
  //   value: "CSFB",
  // },
  {
    name: "Catholic Syrian Bank",
    value: "CSB",
  },
  {
    name: "Central Bank of India",
    value: "CBOI",
  },
  {
    name: "City Union Bank",
    value: "CUB",
  },
  // {
  //   name: "Cosmos Bank",
  //   value: "COSB",
  // },
  // {
  //   name: "DBS Bank",
  //   value: "DBSB",
  // },
  {
    name: "DCB Bank",
    value: "DCBB",
  },
  // {
  //   name: "Deutsche Bank",
  //   value: "DEUTB",
  // },
  // {
  //   name: "Development Credit Bank - Corporate",
  //   value: "DCBC",
  // },
  // {
  //   name: "Dhanlakshmi Bank",
  //   value: "DHANB",
  // },
  // {
  //   name: "Equitas Small Finance Bank",
  //   value: "EQSFB",
  // },
  {
    name: "Federal Bank",
    value: "FEDB",
  },
  // {
  //   name: "Fincare Bank",
  //   value: "FIB",
  // },
  {
    name: "HDFC Bank",
    value: "HDFCB",
  },
  // {
  //   name: "HSBC Bank",
  //   value: "HSBC",
  // },
  { name: "ICICI Bank", code: 3022, abbreviation: "ICICR", value: "ICICR" },
  {
    name: "ICICI Corporate",
    value: "ICICICO",
  },
  {
    name: "IDBI Bank",
    value: "IDBIB",
  },
  {
    name: "IDFC First Bank",
    value: "IDFCFB",
  },
  {
    name: "Indian Bank",
    value: "INB",
  },
  {
    name: "Indian Overseas Bank",
    value: "IOB",
  },
  {
    name: "Indian Overseas Bank - Corporate",
    value: "IOBC",
  },
  {
    name: "IndusInd Bank",
    value: "INDUSB",
  },
  {
    name: "Jammu & Kashmir Bank",
    value: "JKB",
  },
  {
    name: "Jammu & Kashmir Bank - Corporate",
    value: "JKBC",
  },
  // {
  //   name: "Janata Sahakari Bank Ltd Pune",
  //   value: "JSBLP",
  // },
  {
    name: "Karnataka Bank Ltd",
    value: "KBL",
  },
  // {
  //   name: "Karnataka Gramin Bank",
  //   value: "KAGB",
  // },
  {
    name: "Karur Vysya Bank",
    value: "KVB",
  },
  // {
  //   name: "KJSB Bank",
  //   value: "KJSBB",
  // },
  // {
  //   name: "Kotak Bank",
  //   value: "KTB",
  // },
  // {
  //   name: "Lakshmi Vilas Bank",
  //   value: "LVB",
  // },
  // {
  //   name: "Laxmi Vilas Bank - Retail Net Banking",
  //   value: "LVBRNB",
  // },
  // {
  //   name: "PNB (Erstwhile-Oriental Bank of Commerce)",
  //   value: "OBOC",
  // },
  // {
  //   name: "PNB (Erstwhile-United Bank of India)",
  //   value: "UNBOI",
  // },
  // {
  //   name: "Punjab & Sind Bank",
  //   value: "PASB",
  // },
  {
    name: "Punjab National Bank - Corporate Banking",
    value: "PNBCB",
  },
  {
    name: "Punjab National Bank - Retail Banking",
    value: "PNBRB",
  },
  {
    name: "RBL Bank Limited",
    value: "RBLBL",
  },
  // {
  //   name: "Saraswat Bank",
  //   value: "SARB",
  // },
  // {
  //   name: "Shamrao Vithal Co-op Bank",
  //   value: "SVCB",
  // },
  {
    name: "South Indian Bank",
    value: "SIB",
  },
  {
    name: "Standard Chartered Bank",
    value: "SCB",
  },
  {
    name: "Standard Chartered Bank - Corporate",
    value: "SCBC",
  },
  {
    name: "State Bank Of India",
    code: 3044,
    abbreviation: "SBINR",
    value: "SBOI",
  },
  {
    name: "State Bank of India - Corporate Banking",
    value: "SBOIC",
  },
  // {
  //   name: "Tamil Nadu State Co-operative Bank",
  //   value: "TNSCB",
  // },
  {
    name: "Tamilnad Mercantile Bank Ltd.",
    value: "TMBL",
  },
  // {
  //   name: "TJSB Bank",
  //   value: "TJSBB",
  // },
  // {
  //   name: "UCO Bank",
  //   value: "UCOB",
  // },
  // {
  //   name: "UCO Bank Corporate",
  //   value: "UCOBC",
  // },
  {
    name: "Union Bank of India",
    value: "UBOI",
  },
  // {
  //   name: "Union Bank of India - Corporate",
  //   value: "UBOIC",
  // },
  {
    name: "Union Bank of India (Erstwhile Andhra Bank)",
    value: "ANB",
  },
  // {
  //   name: "Union Bank of India (Erstwhile Andhra Bank) - Corporate",
  //   value: "ANBC",
  // },
  // {
  //   name: "Union Bank of India (Erstwhile Corporation Bank)",
  //   value: "CORPB",
  // },
  // {
  //   name: "Union Bank of India (Erstwhile Corporation Bank) - Corporate",
  //   value: "CORPCB",
  // },
  // {
  //   name: "Vijaya Bank",
  //   value: "VIJB",
  // },
  // {
  //   name: "Shivalik Small Finance Bank",
  //   value: "NBSB",
  // },
  { name: "Yes Bank Ltd", code: 3058, abbreviation: "YESBR", value: "YESBL" },
];

const defaultEasebuzzBanks = [
  // {
  //   name: "Allahabad Bank",
  //   value: "ALB",
  // },
  {
    name: "AU Small Finance Bank",
    value: "AUSFB",
  },
  // {
  //   name: "Airtel Payments Bank",
  //   value: "AIRTLM",
  // },
  { name: "Axis Bank", code: 3003, abbreviation: "UTIBR", value: "UTIBR" },

  {
    name: "Axis Corporate Net banking",
    value: "ACNB",
  },
  {
    name: "Bandhan Bank",
    value: "BANB",
  },
  // {
  //   name: "Bandhan Bank Corporate",
  //   value: "BABC",
  // },
  {
    name: "Bank of Baroda - Corporate Banking",
    value: "BOBCB",
  },
  {
    name: "Bank of Baroda - Retail Banking",
    value: "BOBRB",
  },
  {
    name: "Bank of India",
    value: "BOIND",
  },
  {
    name: "Bank of Maharashtra",
    value: "BOMH",
  },
  {
    name: "Canara Bank",
    value: "CANB",
  },
  // {
  //   name: "Canara Bank (Erstwhile Syndicate Bank)",
  //   value: "SYNB",
  // },
  // {
  //   name: "Capital Small Finance Bank",
  //   value: "CSFB",
  // },
  {
    name: "Catholic Syrian Bank",
    value: "CSB",
  },
  {
    name: "Central Bank of India",
    value: "CBOI",
  },
  {
    name: "City Union Bank",
    value: "CUB",
  },
  // {
  //   name: "Cosmos Bank",
  //   value: "COSB",
  // },
  // {
  //   name: "DBS Bank",
  //   value: "DBSB",
  // },
  {
    name: "DCB Bank",
    value: "DCBB",
  },
  // {
  //   name: "Deutsche Bank",
  //   value: "DEUTB",
  // },
  // {
  //   name: "Development Credit Bank - Corporate",
  //   value: "DCBC",
  // },
  // {
  //   name: "Dhanlakshmi Bank",
  //   value: "DHANB",
  // },
  // {
  //   name: "Equitas Small Finance Bank",
  //   value: "EQSFB",
  // },
  {
    name: "Federal Bank",
    value: "FEDB",
  },
  // {
  //   name: "Fincare Bank",
  //   value: "FIB",
  // },
  {
    name: "HDFC Bank",
    value: "HDFCR",
  },
  // {
  //   name: "HSBC Bank",
  //   value: "HSBC",
  // },
  { name: "ICICI Bank", code: 3022, abbreviation: "ICICR", value: "ICICR" },
  {
    name: "ICICI Corporate",
    value: "ICICICO",
  },
  {
    name: "IDBI Bank",
    value: "IDBIB",
  },
  {
    name: "IDFC First Bank",
    value: "IDFCFB",
  },
  {
    name: "Indian Bank",
    value: "INB",
  },
  {
    name: "Indian Overseas Bank",
    value: "IOB",
  },
  {
    name: "Indian Overseas Bank - Corporate",
    value: "IOBC",
  },
  {
    name: "IndusInd Bank",
    value: "INDUSB",
  },
  {
    name: "Jammu & Kashmir Bank",
    value: "JKB",
  },
  {
    name: "Jammu & Kashmir Bank - Corporate",
    value: "JKBC",
  },
  // {
  //   name: "Janata Sahakari Bank Ltd Pune",
  //   value: "JSBLP",
  // },
  {
    name: "Karnataka Bank Ltd",
    value: "KBL",
  },
  // {
  //   name: "Karnataka Gramin Bank",
  //   value: "KAGB",
  // },
  {
    name: "Karur Vysya Bank",
    value: "KVB",
  },
  // {
  //   name: "KJSB Bank",
  //   value: "KJSBB",
  // },
  // {
  //   name: "Kotak Bank",
  //   value: "KTB",
  // },
  // {
  //   name: "Lakshmi Vilas Bank",
  //   value: "LVB",
  // },
  // {
  //   name: "Laxmi Vilas Bank - Retail Net Banking",
  //   value: "LVBRNB",
  // },
  // {
  //   name: "PNB (Erstwhile-Oriental Bank of Commerce)",
  //   value: "OBOC",
  // },
  // {
  //   name: "PNB (Erstwhile-United Bank of India)",
  //   value: "UNBOI",
  // },
  // {
  //   name: "Punjab & Sind Bank",
  //   value: "PASB",
  // },
  {
    name: "Punjab National Bank - Corporate Banking",
    value: "PNBCB",
  },
  {
    name: "Punjab National Bank - Retail Banking",
    value: "PNBRB",
  },
  {
    name: "RBL Bank Limited",
    value: "RBLBL",
  },
  // {
  //   name: "Saraswat Bank",
  //   value: "SARB",
  // },
  // {
  //   name: "Shamrao Vithal Co-op Bank",
  //   value: "SVCB",
  // },
  {
    name: "South Indian Bank",
    value: "SIB",
  },
  {
    name: "Standard Chartered Bank",
    value: "SCB",
  },
  {
    name: "Standard Chartered Bank - Corporate",
    value: "SCBC",
  },
  {
    name: "State Bank Of India",
    code: 3044,
    abbreviation: "SBINR",
    value: "SBINR",
  },
  {
    name: "State Bank of India - Corporate Banking",
    value: "SBOIC",
  },
  // {
  //   name: "Tamil Nadu State Co-operative Bank",
  //   value: "TNSCB",
  // },
  {
    name: "Tamilnad Mercantile Bank Ltd.",
    value: "TMBL",
  },
  // {
  //   name: "TJSB Bank",
  //   value: "TJSBB",
  // },
  // {
  //   name: "UCO Bank",
  //   value: "UCOB",
  // },
  // {
  //   name: "UCO Bank Corporate",
  //   value: "UCOBC",
  // },
  {
    name: "Union Bank of India",
    value: "UBOI",
  },
  // {
  //   name: "Union Bank of India - Corporate",
  //   value: "UBOIC",
  // },
  {
    name: "Union Bank of India (Erstwhile Andhra Bank)",
    value: "ANB",
  },
  // {
  //   name: "Union Bank of India (Erstwhile Andhra Bank) - Corporate",
  //   value: "ANBC",
  // },
  // {
  //   name: "Union Bank of India (Erstwhile Corporation Bank)",
  //   value: "CORPB",
  // },
  // {
  //   name: "Union Bank of India (Erstwhile Corporation Bank) - Corporate",
  //   value: "CORPCB",
  // },
  // {
  //   name: "Vijaya Bank",
  //   value: "VIJB",
  // },
  // {
  //   name: "Shivalik Small Finance Bank",
  //   value: "NBSB",
  // },
  { name: "Yes Bank Ltd", code: 3058, abbreviation: "YESBR", value: "YESBR" },
];

export { Banks, EasebuzzBanks, defaultEasebuzzBanks };
